import React, { useState, useEffect } from "react";
import {
  UpdateMiraComponentWrapper,
  UpdateMiraContainerWrapper,
} from "./UpdateMira.style";
import InterfaceHeader from "../InterfaceHeader";
import axios from "axios";
import { Button, Checkbox, Modal, Select, Spin, notification } from "antd";
import configApi from "../../config/Api";
import UpdateTable from "./UpdateTable";
const toTitleCase = (str) => {
  return (str + "").toUpperCase();
};
const openNotification = (msg) => {
  let args,
    key = msg;
  if (key === true) {
    args = {
      message: "Success",
      description: "Purge successful",
      duration: 0,
      placement: "bottomLeft",
    };
    notification.success(args);
  } else if (key === false) {
    args = {
      message: "Failure",
      description: "Failed to purge titles",
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.error(args);
  } else {
    args = {
      message: "Warning",
      description: key,
      duration: 3.5,
      placement: "bottomLeft",
    };
    notification.warning(args);
  }
};
function UpdateMiraContainer() {
  const [infoTabVis, setInfoTabVis] = useState(false);
  const [hubData, setHubData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [onGoingApiCall, setOnGoingApiCall] = useState(false);
  const [selectedHub, setSelectedHub] = useState(null);
  const [sendingLoading, setSendingLoading] = useState(false);
  const [titlesList, setTitlesList] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const toggleInfoTab = () => {
    setInfoTabVis((p) => !p);
  };
  let cancelTokenOfTitles = axios.CancelToken.source();
  const getHubList = async () => {
    if (!hubData) {
      let full_access = false;
      await axios
        .get(
          `${configApi.api.base_url}/api/hubs/list?full_access=${full_access}`
        )
        .then((response) => {
          if (response.status === 200) {
            setHubData(response.data.obj);
          }
          setLoading(false);
        })
        .catch((error) => {
          openNotification("Unable to load hub list");
          setLoading(false);
        });
    }
  };

  const getListTitles = () => {
    if (!selectedHub) {
      openNotification("Please select a Hub");
    }

    let payload = {
      hub_id: selectedHub,
    };
    setTitlesList([]);

    setLoading(true);

    axios
      .post(
        `${configApi.api.base_url}/subscription/reportListRawData`,
        payload,
        {
          cancelToken: cancelTokenOfTitles.token,
        }
      )
      .then((res) => {
        if (res.data.reports.length) {
          setTitlesList(res?.data.reports);
        } else {
          setTitlesList([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        cancelTokenOfTitles = axios.CancelToken.source();
        setTitlesList([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getHubList();
  }, []);

  const sendToProdGain = async () => {
    setSendingLoading(true);
    try {
      await axios.post(
        `${configApi.api.base_url}/subscription/update-mira`,
        {
          mi_id: selectedTitles,
        },
        {
          cancelToken: cancelTokenOfTitles.token,
        }
      );
      setConfirmModal(false);
      getListTitles();
      setSendingLoading(false);
      notification.success("updated Sucessfully");
    } catch (e) {
      console.log(e);
      setSendingLoading(false);
      notification.error("error");
    }
  };

  const selectTitles = (e, item) => {
    if (!item.mi_id) {
      notification.error({
        message: "Rd is not Published",
      });

      notification.error({
        message: "if Rd is published, please try regeneration the report",
      });
    }

    const find = selectedTitles.find((tit) => tit === item.mi_id);
    if (find) {
      let finalTitles = [];
      finalTitles = selectedTitles.filter((itm) => itm !== item.mi_id);
      console.log(finalTitles, "finalTitles");
      setSelectedTitles(finalTitles);
    } else {
      setSelectedTitles((p) => {
        return [...p, item.mi_id];
      });
    }
  };

  return (
    <>
      <InterfaceHeader />
      <UpdateMiraContainerWrapper>
        <UpdateMiraComponentWrapper>
          <div style={{ marginTop: "6rem" }} className="card">
            <div
              className="leftContainer"
              style={{
                width: infoTabVis ? "70%" : "100%",
              }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h2
                  style={{
                    textAlign: "center",
                    flexGrow: 1,
                    marginTop: "20px",
                  }}>
                  Update myRA on Synapses
                </h2>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: " space-between",
                  gap: "10px",
                  flexWrap: "wrap",
                }}>
                <div className="selectDropdownContainer">
                  <p className="exportLabels">
                    {" "}
                    Select Hub : <span className="mandatory">*</span> :{" "}
                  </p>
                  <Select
                    disabled={onGoingApiCall}
                    id="SelectHub"
                    placeholder="Select Hub"
                    defaultValue="Select Hub"
                    value={selectedHub}
                    style={{
                      minWidth: "200px",
                    }}
                    loading={loading}
                    maxLength=""
                    onSelect={(e) => {
                      setSelectedHub(e);

                      setTitlesList([]);
                    }}
                    options={
                      hubData
                        ?.filter((item, index) => item.name !== "ZEROHUB")
                        ?.map((option) => ({
                          value: option.id,
                          label: toTitleCase(option.name),
                        })) || []
                    }
                  />
                </div>

                <div
                  style={{
                    margin: "15px 0",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                  }}>
                  {" "}
                  {selectedHub && (
                    <Button onClick={getListTitles} type="primary">
                      Get Titles List
                    </Button>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}>
                  {" "}
                  <Spin tip="Loading..." spinning={loading}></Spin>
                  <div style={{ width: "100%" }}>
                    {titlesList && titlesList.length > 0 ? (
                      <>
                        <div
                          style={{
                            gap: "15px",
                            overflow: "scroll",
                            maxHeight: "400px",
                          }}
                          className="titlesWrapper">
                          <table
                            style={{
                              top: "0px",
                              position: "sticky",
                              zIndex: 1,
                            }}>
                            <tbody>
                              <tr>
                                <td>Name </td>
                                <td style={{ width: "30%" }}>
                                  Last Pushed by{" "}
                                </td>
                                <td style={{ width: "20%" }}>
                                  Last Updated on{" "}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          {titlesList?.map((item, i) => (
                            <div
                              className="hoverEffect"
                              style={{
                                background: " #80808008",
                                padding: "5px",
                                borderRadius: "5px",
                                display: "flex",
                                alignItems: "center",
                                margin: "0px 20px",
                                justifyContent: "space-between",
                                opacity: !item.mi_id ? "0.5" : "1",
                                pointerEvents:
                                  sendingLoading != "" ? "none" : "unset",
                              }}
                              key={i}>
                              <UpdateTable
                                selectTitles={selectTitles}
                                item={item}
                                selectedTitles={selectedTitles}
                              />
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      // : loading ? (
                      //   ""
                      // )

                      titlesList?.length === 0 && (
                        <h4>* No titles are present</h4>
                      )
                    )}
                  </div>
                </div>
              </div>
              {selectedTitles.length > 0 && (
                <div
                  style={{
                    margin: "15px 0",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "20px",
                    width: "100%",
                  }}>
                  <Button onClick={() => setConfirmModal(true)} type="primary">
                    Update Selected Titles
                  </Button>
                </div>
              )}
            </div>
            <div className={infoTabVis ? "infoContainer" : "info"}>
              {/* <InfoCircleOutlined title="Company profile only .png and Executive summary only .svg" /> */}
              <Button
                type="default"
                danger={infoTabVis}
                onClick={toggleInfoTab}>
                {infoTabVis ? "Close Guide" : "Open Guide"}
              </Button>
              {infoTabVis && (
                <div
                  className="infoContent"
                  style={{ overflow: "scroll", maxHeight: "350px" }}>
                  <b>
                    Bulk Export Allows you to export the reports, samples and
                    RD's in Bulk
                  </b>
                  <br />
                  <br />
                  <div>
                    <p>⏺ Select the Hub from the dropdown</p>
                    <p>
                      ⏺ if you want to export all titles Click list titles
                      button or Select the Region and Country from the dropdown
                    </p>
                    <p>⏺ Select the type of export you want to do</p>
                    <p>
                      ⏺ Click on the "List Titles" button to list the titles
                    </p>
                    <p>⏺ Select the titles you want to export</p>
                    <p>⏺ Click on the "Export" button to export the titles</p>
                    <br />
                    <br />
                    <h3>-----INFO-----</h3>
                    <p>
                      <b>Reports</b>: are the full reports
                    </p>
                    <p>
                      <b>Samples</b>: are the sample reports
                    </p>
                    <p>
                      <b>RD's</b>: are the Report Descriptions witch is visible
                      on the website.Post the RD's Generation you can find the
                      RDs{" "}
                      <a
                        target="_blank"
                        href="https://lorien.mordorintelligence.com/market-activities">
                        here{" "}
                      </a>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </UpdateMiraComponentWrapper>
      </UpdateMiraContainerWrapper>
      {confirmModal && (
        <Modal
          title="Notification"
          visible={confirmModal}
          confirmLoading={sendingLoading}
          onCancel={() => setConfirmModal(false)}
          onOk={sendToProdGain}
          okText="Continue"
          cancelText="Cancel">
          Are you sure you want to update the selected titles on Mira Synapse?
          Ensure the selected titles have significant changes to be pushed to
          Synapse. Make sure you have read the document/guide before proceeding.
        </Modal>
      )}
    </>
  );
}

export default UpdateMiraContainer;
